function instafeed() {
    console.log("on the instafeed");
    var userFeed = new Instafeed({
        get: 'user',
        target: "instafeed-container",
        resolution: 'low_resolution',
        accessToken: 'IGQVJYWHhOSUdFOGZAvSjEtZAmx2cXo0a2EtLUJGejNmQnlWSUdCaG0xUzZABNkE0UGc3STZA6dWp1VVdGcXZA4UGt4dkk5WUZA4WGpCUXJQcm9yNEpNOTVua3NuSUVPSWFuckg1ekQ1T0thQURHbHIwa3kyaAZDZD',
        limit: 6,
        template: '<a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" /></a>' 
    });
    userFeed.run();
    console.log("buttom of instafeed");
}
function hello() {
    console.log("this is instafeed.js");
}
